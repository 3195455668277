"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _lodash = _interopRequireDefault(require("lodash"));
var settingTools = _interopRequireWildcard(require("@/libs/settingTools"));
function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var _default = {
  data: function data() {
    return {
      filterString: ''
    };
  },
  computed: {
    filteredSettings: function filteredSettings() {
      var settings = this.settings;
      var filter = this.filterString.toLowerCase();
      var out = [];
      (0, _keys.default)(settings).forEach(function (key) {
        var value = settings[key];
        if (value.key.toLowerCase().indexOf(filter) !== -1) {
          out.push(value);
        }
      });
      return out;
    },
    settings: function settings() {
      var _context;
      var out = {};
      var base = [];
      settingTools.buildTree(out, base, this.$state.getSetting('settings'), false);
      settingTools.buildTree(out, base, this.$state.getSetting('user_settings'), true);
      return _lodash.default.orderBy((0, _map.default)(_context = (0, _keys.default)(out)).call(_context, function (key) {
        return out[key];
      }), [function (o) {
        return o.key.split('.').length - 1;
      }, 'key'], ['asc']);
    }
  },
  methods: {
    resetValue: function resetValue(event, settingKey) {
      var newVal = this.$state.getSetting('settings.' + settingKey);
      if (!newVal) {
        newVal = null;
      }
      this.$state.setting(settingKey, newVal);
    },
    updateSetting: function updateSetting(event, settingKey) {
      var target = event.target;
      var val = target.type === 'checkbox' ? target.checked : target.value;
      switch (target.type) {
        case 'checkbox':
          val = target.checked;
          break;
        case 'number':
          val = parseInt(target.value, 10);
          break;
        default:
          val = target.value;
          break;
      }
      if (this.$state.setting(settingKey) === val) {
        return;
      }
      this.$state.setting(settingKey, val);
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["SettingsAdvanced"]
window._kiwi_exports.components.SettingsAdvanced = exports.default ? exports.default : exports;
