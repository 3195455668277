"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-channelbanlist"
  }, [_c('form', {
    staticClass: "u-form kiwi-channelbanlist",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('a', {
    staticClass: "u-link",
    on: {
      "click": _vm.updateBanlist
    }
  }, [_vm._v(_vm._s(_vm.$t('bans_refresh')))]), _vm.banlist.length > 0 ? _c('table', {
    staticClass: "kiwi-channelbanlist-table"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('bans_user')))]), _c('th'), _c('th')]), _vm._l(_vm.banlist, function (ban) {
    return _c('tr', {
      key: ban.banned,
      attrs: {
        "title": 'By ' + ban.banned_by
      }
    }, [_c('td', {
      staticClass: "kiwi-channelbanlist-table-mask"
    }, [_vm._v(_vm._s(ban.banned))]), _c('td', {
      staticClass: "kiwi-channelbanlist-table-bannedat"
    }, [_vm._v(" " + _vm._s(new Date(ban.banned_at * 1000).toDateString()) + " ")]), _c('td', {
      staticClass: "kiwi-channelbanlist-table-actions"
    }, [_c('i', {
      staticClass: "fa fa-trash",
      attrs: {
        "aria-hidden": "true"
      },
      on: {
        "click": function click($event) {
          return _vm.removeBan(ban.banned);
        }
      }
    })])]);
  })], 2) : _vm.is_refreshing ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('bans_refreshing')) + " ")]) : _c('div', {
    staticClass: "kiwi-channelbanlist-empty"
  }, [_vm._v(" " + _vm._s(_vm.$t('bans_nobody')) + " ")])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;