"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-settings-advanced"
  }, [_c('div', {
    staticClass: "kiwi-settings-advanced-notice"
  }, [_vm._v(_vm._s(_vm.$t('settings_advanced_header')))]), _c('form', {
    staticClass: "u-form"
  }, [_c('div', {
    staticClass: "kiwi-settings-advanced-filter-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filterString,
      expression: "filterString"
    }],
    staticClass: "u-input",
    attrs: {
      "placeholder": _vm.$t('settings_advanced_filter')
    },
    domProps: {
      "value": _vm.filterString
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.filterString = $event.target.value;
      }
    }
  }), !_vm.filterString ? _c('i', {
    staticClass: "fa fa-search",
    attrs: {
      "aria-hidden": "true"
    }
  }) : _c('i', {
    staticClass: "fa fa-times",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function click($event) {
        _vm.filterString = '';
      }
    }
  })]), _c('table', {
    staticClass: "u-table kiwi-settings-advanced-table",
    attrs: {
      "cellspacing": "0"
    }
  }, [_vm.filteredSettings.length === 0 ? _c('tr', [_c('td', {
    staticClass: "kiwi-settings-advanced-noresult"
  }, [_vm._v(" " + _vm._s(_vm.filterString) + " - " + _vm._s(_vm.$t('not_found')) + " ")])]) : _vm._l(_vm.filteredSettings, function (setting) {
    return _c('tr', {
      key: setting.key,
      class: {
        'kiwi-advanced-setting': !setting.modified,
        'kiwi-advanced-setting--modified': setting.modified
      }
    }, [_c('td', [_c('label', {
      attrs: {
        "for": 'setting-' + setting.key
      }
    }, [_vm._v(_vm._s(setting.key))])]), setting.modified ? _c('td', [_c('a', {
      staticClass: "u-link",
      on: {
        "click": function click($event) {
          return _vm.resetValue($event, setting.key);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('settings_advanced_reset')) + " "), _c('i', {
      staticClass: "fa fa-undo",
      staticStyle: {
        "margin-left": "10px"
      }
    })])]) : _c('td'), _c('td', [setting.type === 'boolean' ? _c('input', {
      attrs: {
        "id": 'setting-' + setting.key,
        "type": "checkbox"
      },
      domProps: {
        "checked": setting.val
      },
      on: {
        "change": function change($event) {
          return _vm.updateSetting($event, setting.key);
        }
      }
    }) : setting.type === 'number' ? _c('input', {
      staticClass: "u-input",
      attrs: {
        "id": 'setting-' + setting.key,
        "type": "number"
      },
      domProps: {
        "value": setting.val
      },
      on: {
        "keydown": function keydown($event) {
          if (!$event.type.indexOf('key') && $event.keyCode !== 13) return null;
          return $event.target.blur();
        },
        "change": function change($event) {
          return _vm.updateSetting($event, setting.key);
        },
        "blur": function blur($event) {
          return _vm.updateSetting($event, setting.key);
        }
      }
    }) : _c('input', {
      staticClass: "u-input",
      attrs: {
        "id": 'setting-' + setting.key
      },
      domProps: {
        "value": setting.val
      },
      on: {
        "keydown": function keydown($event) {
          if (!$event.type.indexOf('key') && $event.keyCode !== 13) return null;
          return $event.target.blur();
        },
        "blur": function blur($event) {
          return _vm.updateSetting($event, setting.key);
        }
      }
    })])]);
  })], 2)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;