"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-bufferkey kiwi-warning-block"
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.joinChannel.apply(null, arguments);
      }
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t('bufferkey_input')))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.buffer.key,
      expression: "buffer.key"
    }, {
      name: "focus",
      rawName: "v-focus"
    }],
    attrs: {
      "type": "password"
    },
    domProps: {
      "value": _vm.buffer.key
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.buffer, "key", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "kiwi-bufferkey-buttons"
  }, [_c('span', {
    staticClass: "kiwi-bufferkey-button",
    on: {
      "click": _vm.joinChannel
    }
  }, [_c('i', {
    staticClass: "fa fa-arrow-circle-o-right",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('bufferkey_rejoin', {
    channel: _vm.buffer.name
  })) + " ")])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;