"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render(_c, _vm) {
  return _c('div', {
    staticClass: "kiwi-avatar",
    class: [_vm.$options.m.hasAvatar(_vm.props) ? 'kiwi-avatar--image' : '', _vm.data.staticClass],
    attrs: {
      "data-nick": _vm.$options.m.nick(_vm.props)
    }
  }, [_c('span', {
    staticClass: "kiwi-avatar-inner",
    class: {
      'kiwi-avatar--default': _vm.$options.m.isDefault(_vm.props)
    },
    style: _vm.$options.m.avatarStyle(_vm.props)
  }, [_vm._v(" " + _vm._s(_vm.$options.m.hasAvatar(_vm.props) ? '' : _vm.$options.m.firstNickLetter(_vm.props)) + " ")])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;