"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-personal"
  }, [_c('h1', [_vm._v(_vm._s(_vm.$t('personal_client')))]), _vm.server && _vm.server.server && !_vm.readyToShowOptions ? _c('div') : _vm.server && _vm.server.server ? _c('div', [_vm.addedNetworkToExisting ? _c('div', [_c('p', [_vm._v(" " + _vm._s(_vm.$t('personal_added_to_existing')) + " ")])]) : _c('div', [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('personal_connect_to', {
        network: "<b>".concat(_vm.server.server, "</b>")
      }))
    }
  }), _vm.hasOtherTab ? _c('button', {
    staticClass: "u-button u-button-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.addNetworkToExistingTab
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('personal_add_existing_tab')) + " ")]) : _c('button', {
    staticClass: "u-button u-button-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.addNetwork();
      }
    }
  }, [_vm._v(" Add network to Kiwi ")]), _c('br'), _c('button', {
    staticClass: "u-button u-button-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.addNetwork(true);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('personal_connect_temporary')) + " ")]), _vm._v(" "), _c('br')])]) : _c('div', [_c('p', [_vm._v(_vm._s(_vm.$t('personal_addjoin')))]), _c('p', [_vm._v(_vm._s(_vm.$t('personal_return')))]), _c('button', {
    staticClass: "u-button u-button-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.addEmptyNetwork
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('personal_add')) + " ")]), _vm._v(" "), _c('br'), _vm.networks.length > 0 ? _c('a', {
    staticClass: "u-link kiwi-personal-existing-networks",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleStateBrowser.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('personal_saved')) + " ")]) : _vm._e()])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;