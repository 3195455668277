"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "u-input-text"
  }, [_vm.label ? _c('label', {
    attrs: {
      "for": _vm.inputId
    }
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c('div', {
    staticClass: "u-input-text-inputs",
    staticStyle: {
      "display": "flex"
    }
  }, [_vm.type === 'password' ? [(_vm.plainTextEnabled && !_vm.isEdgeBrowser() ? 'text' : 'password') === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentValue,
      expression: "currentValue"
    }],
    staticClass: "u-input",
    class: {
      'u-form-input-plaintext': !_vm.isEdgeBrowser() && _vm.showPlainText
    },
    attrs: {
      "id": _vm.inputId,
      "autocomplete": "off",
      "autocorrect": "off",
      "autocapitalize": "off",
      "spellcheck": "false",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.currentValue) ? _vm._i(_vm.currentValue, null) > -1 : _vm.currentValue
    },
    on: {
      "keypress": function keypress($event) {
        return _vm.$emit('keypress', $event);
      },
      "change": function change($event) {
        var $$a = _vm.currentValue,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.currentValue = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context;
            $$i > -1 && (_vm.currentValue = (0, _concat.default)(_context = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.currentValue = $$c;
        }
      }
    }
  }) : (_vm.plainTextEnabled && !_vm.isEdgeBrowser() ? 'text' : 'password') === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentValue,
      expression: "currentValue"
    }],
    staticClass: "u-input",
    class: {
      'u-form-input-plaintext': !_vm.isEdgeBrowser() && _vm.showPlainText
    },
    attrs: {
      "id": _vm.inputId,
      "autocomplete": "off",
      "autocorrect": "off",
      "autocapitalize": "off",
      "spellcheck": "false",
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.currentValue, null)
    },
    on: {
      "keypress": function keypress($event) {
        return _vm.$emit('keypress', $event);
      },
      "change": function change($event) {
        _vm.currentValue = null;
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentValue,
      expression: "currentValue"
    }],
    staticClass: "u-input",
    class: {
      'u-form-input-plaintext': !_vm.isEdgeBrowser() && _vm.showPlainText
    },
    attrs: {
      "id": _vm.inputId,
      "autocomplete": "off",
      "autocorrect": "off",
      "autocapitalize": "off",
      "spellcheck": "false",
      "type": _vm.plainTextEnabled && !_vm.isEdgeBrowser() ? 'text' : 'password'
    },
    domProps: {
      "value": _vm.currentValue
    },
    on: {
      "keypress": function keypress($event) {
        return _vm.$emit('keypress', $event);
      },
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.currentValue = $event.target.value;
      }
    }
  }), _vm.showPlainText && !_vm.isEdgeBrowser() ? _c('i', {
    staticClass: "u-input-text-plaintext fa fa-eye",
    class: {
      'u-input-text-plaintext--active': _vm.plainTextEnabled
    },
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function click($event) {
        _vm.plainTextEnabled = !_vm.plainTextEnabled;
      }
    }
  }) : _vm._e()] : _vm.type === 'number' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentValue,
      expression: "currentValue"
    }],
    staticClass: "u-input",
    attrs: {
      "id": _vm.inputId,
      "type": "number"
    },
    domProps: {
      "value": _vm.currentValue
    },
    on: {
      "keypress": function keypress($event) {
        return _vm.$emit('keypress', $event);
      },
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.currentValue = $event.target.value;
      }
    }
  }) : _vm.type === 'textarea' ? _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentValue,
      expression: "currentValue"
    }],
    staticClass: "u-input",
    attrs: {
      "id": _vm.inputId
    },
    domProps: {
      "value": _vm.currentValue
    },
    on: {
      "keypress": function keypress($event) {
        return _vm.$emit('keypress', $event);
      },
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.currentValue = $event.target.value;
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentValue,
      expression: "currentValue"
    }],
    staticClass: "u-input",
    attrs: {
      "id": _vm.inputId,
      "autocomplete": "off",
      "autocorrect": "off",
      "autocapitalize": "off",
      "spellcheck": "false"
    },
    domProps: {
      "value": _vm.currentValue
    },
    on: {
      "keypress": function keypress($event) {
        return _vm.$emit('keypress', $event);
      },
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.currentValue = $event.target.value;
      }
    }
  }), _vm.$slots.default ? _c('div', {
    staticClass: "u-input-text-c"
  }, [_vm._t("default")], 2) : _vm._e()], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;