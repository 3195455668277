"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('transition', {
    attrs: {
      "name": "u-transition-expand"
    },
    on: {
      "enter": _vm.enter,
      "after-enter": _vm.afterEnter,
      "leave": _vm.leave
    }
  }, [_vm.$slots.default ? _c('div', [_vm._t("default")], 2) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;