"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-invitelist"
  }, [_c('form', {
    staticClass: "u-form kiwi-invitelist",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('a', {
    staticClass: "u-link",
    on: {
      "click": _vm.updateInvitelist
    }
  }, [_vm._v(_vm._s(_vm.$t('invites_refresh')))]), !_vm.channelIsInviteOnly ? _c('div', {
    staticClass: "kiwi-invitelist-inviteonly-status"
  }, [_vm._v(" " + _vm._s(_vm.$t('invite_public_channel')) + " "), _vm.areWeAnOp ? _c('a', {
    staticClass: "u-link",
    on: {
      "click": _vm.setInviteOnly
    }
  }, [_vm._v(_vm._s(_vm.$t('invite_set_private')))]) : _vm._e()]) : _c('div', {
    staticClass: "kiwi-invitelist-inviteonly-status"
  }, [_vm._v(" " + _vm._s(_vm.$t('invite_private_channel')) + " "), _vm.areWeAnOp ? _c('a', {
    staticClass: "u-link",
    on: {
      "click": _vm.removeInviteOnly
    }
  }, [_vm._v(_vm._s(_vm.$t('invite_set_public')))]) : _vm._e()]), _vm.anyRegisteredUserCanJoin ? _c('span', [_vm._v(_vm._s(_vm.$t('invite_registered_only')))]) : _vm._e(), _c('div', [_vm.supportsAccounts && _vm.areWeAnOp ? _c('div', [_vm.knownAccounts.length > 0 ? [_c('select', {
    ref: "addInviteList"
  }, _vm._l(_vm.knownAccounts, function (user) {
    return _c('option', {
      key: user.key,
      domProps: {
        "value": user.account
      }
    }, [_vm._v(_vm._s(user.account))]);
  }), 0), _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.addAccountInvite(_vm.$refs.addInviteList.value);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invite_add_invite')) + " ")])] : _vm._e()], 2) : _vm._e(), !_vm.supportsAccounts && _vm.areWeAnOp ? _c('div', {
    staticClass: "kiwi-invitelist-addmask"
  }, [_c('input', {
    ref: "addInviteText",
    staticClass: "u-input",
    attrs: {
      "type": "text"
    }
  }), _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.addInvite(_vm.$refs.addInviteText.value);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invite_add_invite')) + " ")])]) : _vm._e(), _vm.inviteList.length > 0 ? _c('table', {
    staticClass: "kiwi-invitelist-table"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('invites_user')))]), _c('th', [_vm._v(_vm._s(_vm.$t('invites_by')))]), _c('th'), _c('th')]), _vm._l(_vm.inviteListAccounts, function (invite) {
    return _c('tr', {
      key: invite.invited
    }, [_c('td', {
      staticClass: "kiwi-invitelist-table-mask"
    }, [_vm._v(" " + _vm._s(_vm.displayMask(invite)) + " ")]), _c('td', {
      staticClass: "kiwi-invitelist-table-invitedby"
    }, [_vm._v(" " + _vm._s(invite.invited_by) + " ")]), _c('td', {
      staticClass: "kiwi-invitelist-table-invitedat"
    }, [_vm._v(" " + _vm._s(new Date(invite.invited_at * 1000).toDateString()) + " ")]), _c('td', {
      staticClass: "kiwi-invitelist-table-actions"
    }, [_vm.areWeAnOp ? _c('i', {
      staticClass: "fa fa-trash",
      attrs: {
        "aria-hidden": "true"
      },
      on: {
        "click": function click($event) {
          return _vm.removeInvite(invite.invited);
        }
      }
    }) : _vm._e()])]);
  }), _vm._l(_vm.inviteListNonAccounts, function (invite) {
    return _c('tr', {
      key: invite.invited
    }, [_c('td', {
      staticClass: "kiwi-invitelist-table-mask"
    }, [_vm._v(" " + _vm._s(_vm.displayMask(invite)) + " ")]), _c('td', {
      staticClass: "kiwi-invitelist-table-invitedby"
    }, [_vm._v(" " + _vm._s(invite.invited_by) + " ")]), _c('td', {
      staticClass: "kiwi-invitelist-table-invitedat"
    }, [_vm._v(" " + _vm._s(new Date(invite.invited_at * 1000).toDateString()) + " ")]), _c('td', {
      staticClass: "kiwi-invitelist-table-actions"
    }, [_vm.areWeAnOp ? _c('i', {
      staticClass: "fa fa-trash",
      attrs: {
        "aria-hidden": "true"
      },
      on: {
        "click": function click($event) {
          return _vm.removeInvite(invite.invited);
        }
      }
    }) : _vm._e()])]);
  })], 2) : _vm._e()]), _vm.is_refreshing ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('invites_refreshing')) + " ")]) : _vm._e()])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;