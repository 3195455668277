"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AwayStatusIndicator = _interopRequireDefault(require("./AwayStatusIndicator"));
var _default = {
  components: {
    AwayStatusIndicator: _AwayStatusIndicator.default
  },
  props: ['buffer', 'activePrompt'],
  computed: {
    network: function network() {
      return this.buffer.getNetwork();
    },
    showPromptClose: function showPromptClose() {
      return this.activePrompt && this.activePrompt.type === 'buffer' && this.activePrompt.value === this.buffer;
    }
  },
  methods: {
    isActiveBuffer: function isActiveBuffer() {
      var buffer = this.buffer;
      return buffer.networkid === this.$state.ui.active_network && buffer.name === this.$state.ui.active_buffer;
    },
    awayNotifySupported: function awayNotifySupported() {
      return this.network.ircClient.network.cap.isEnabled('away-notify');
    },
    showMessageCounts: function showMessageCounts() {
      return !this.buffer.setting('hide_message_counts');
    },
    maybePromptClose: function maybePromptClose() {
      var setting = this.buffer.setting('prompt_leave');
      if (setting === 'none' || setting !== 'all' && (this.buffer.isChannel() && setting !== 'channels' || this.buffer.isQuery() && setting !== 'queries' || this.buffer.isSpecial() && setting !== 'queries')) {
        // Prompt feature is disabled, just close the buffer
        this.closeBuffer();
        return;
      }
      var prompt = this.activePrompt;
      if (this.showPromptClose) {
        // Prompt is currently visible so close it
        prompt.type = undefined;
        prompt.value = undefined;
      } else {
        prompt.type = 'buffer';
        prompt.value = this.buffer;
      }
    },
    closeBuffer: function closeBuffer() {
      this.$state.removeBuffer(this.buffer);
    }
  }
};
exports.default = _default;