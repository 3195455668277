"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));
var _GlobalApi = _interopRequireDefault(require("@/libs/GlobalApi"));
var _Html = _interopRequireDefault(require("@/libs/renderers/Html"));
var _MessageParser = _interopRequireDefault(require("@/libs/MessageParser"));
var _BufferSettings = _interopRequireDefault(require("./BufferSettings"));
var _ChannelInfo = _interopRequireDefault(require("./ChannelInfo"));
var _ChannelBanlist = _interopRequireDefault(require("./ChannelBanlist"));
var _AwayStatusIndicator = _interopRequireDefault(require("./AwayStatusIndicator"));
var _default = {
  components: {
    BufferSettings: _BufferSettings.default,
    ChannelInfo: _ChannelInfo.default,
    ChannelBanlist: _ChannelBanlist.default,
    AwayStatusIndicator: _AwayStatusIndicator.default
  },
  props: ['buffer', 'sidebarState'],
  data: function data() {
    return {
      self: this,
      pluginUiChannelElements: _GlobalApi.default.singleton().channelHeaderPlugins,
      pluginUiQueryElements: _GlobalApi.default.singleton().queryHeaderPlugins
    };
  },
  computed: {
    isJoined: function isJoined() {
      var buffer = this.buffer;
      return buffer.getNetwork().state === 'connected' && buffer.joined;
    },
    isConnected: function isConnected() {
      return this.buffer.getNetwork().state === 'connected';
    },
    shouldShowTopic: function shouldShowTopic() {
      var _context;
      return !this.$state.ui.is_narrow && (0, _trim.default)(_context = this.buffer.topic).call(_context) && this.buffer.setting('show_topic_in_header');
    },
    formattedTopic: function formattedTopic() {
      var blocks = (0, _MessageParser.default)(this.buffer.topic, {
        extras: false
      });
      var content = (0, _Html.default)(blocks);
      return content;
    },
    network: function network() {
      return this.buffer.getNetwork();
    },
    sidebarSection: function sidebarSection() {
      return this.sidebarState.section();
    },
    userOnline: function userOnline() {
      var user = this.$state.getUser(this.buffer.getNetwork().id, this.buffer.name);
      return !!user;
    }
  },
  methods: {
    isChannel: function isChannel() {
      return this.buffer.isChannel();
    },
    isServer: function isServer() {
      return this.buffer.isServer();
    },
    isQuery: function isQuery() {
      return this.buffer.isQuery();
    },
    isSpecial: function isSpecial() {
      return this.buffer.isSpecial();
    },
    showNetworkSettings: function showNetworkSettings(network) {
      network.showServerBuffer('settings');
    },
    onConnectButtonClick: function onConnectButtonClick() {
      var network = this.buffer.getNetwork();
      if (!network.connection.server) {
        network.showServerBuffer('settings');
      } else {
        network.ircClient.connect();
      }
    },
    toggleUser: function toggleUser() {
      var user = this.$state.getUser(this.buffer.getNetwork().id, this.buffer.name);
      this.sidebarState.toggleUser(user);
    },
    joinCurrentBuffer: function joinCurrentBuffer() {
      var network = this.buffer.getNetwork();
      this.buffer.enabled = true;
      network.ircClient.join(this.buffer.name);
    },
    onHeaderClick: function onHeaderClick(event) {
      var channelName = event.target.getAttribute('data-channel-name');
      if (channelName) {
        var network = this.buffer.getNetwork();
        this.$state.addBuffer(this.buffer.networkid, channelName);
        network.ircClient.join(channelName);
      }
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["ContainerHeader"]
window._kiwi_exports.components.ContainerHeader = exports.default ? exports.default : exports;
