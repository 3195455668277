"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-typinguserslist"
  }, [_vm._l(_vm.typingUsers, function (user, idx) {
    return _c('span', {
      key: user.key,
      style: {
        color: _vm.userColour(user)
      }
    }, [_vm._v(" " + _vm._s(user.nick) + _vm._s(_vm.typingUsers.length - 1 > idx ? ',' : '') + " ")]);
  }), _vm.typingUsers.length > 0 ? _c('span', {
    staticClass: "kiwi-typing"
  }) : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;