"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-customserver",
    class: [_vm.is_connecting ? 'kiwi-customserver--connecting' : '']
  }, [_c('div', {
    staticClass: "kiwi-customserver-container"
  }, [!_vm.is_connecting ? _c('h2', {
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  }) : _c('h2', [_vm._v(" " + _vm._s(_vm.$t('connecting')) + " "), _c('a', {
    staticClass: "u-link",
    on: {
      "click": _vm.infoClick
    }
  }, [_c('i', {
    staticClass: "fa fa-info-circle",
    attrs: {
      "aria-hidden": "true"
    }
  })])]), _c('transition', {
    attrs: {
      "name": "kiwi-connectingloader"
    }
  }, [!_vm.is_connecting ? _c('form', {
    staticClass: "u-form u-form--big kiwi-customserver-form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.startUp.apply(null, arguments);
      }
    }
  }, [_vm.network && _vm.network.state_error ? _c('div', {
    staticClass: "kiwi-customserver-error"
  }, [_vm._v(" We couldn't connect to the server :( "), _c('span', [_vm._v(_vm._s(_vm.readableStateError(_vm.network.state_error)))])]) : _vm._e(), _vm.server_type === 'default' ? [_c('input-text', {
    attrs: {
      "label": _vm.$t('server')
    },
    model: {
      value: _vm.server,
      callback: function callback($$v) {
        _vm.server = $$v;
      },
      expression: "server"
    }
  }, [_c('span', {
    staticClass: "fa-stack fa-lg kiwi-customserver-tls",
    class: [_vm.tls ? 'kiwi-customserver-tls--enabled' : ''],
    on: {
      "click": function click($event) {
        _vm.tls = !_vm.tls;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-lock fa-stack-1x kiwi-customserver-tls-lock"
  }), !_vm.tls ? _c('i', {
    staticClass: "fa fa-times fa-stack-1x kiwi-customserver-tls-minus"
  }) : _vm._e()])]), _c('input-text', {
    staticClass: "kiwi-customserver-nick",
    attrs: {
      "label": _vm.$t('nick')
    },
    model: {
      value: _vm.nick,
      callback: function callback($$v) {
        _vm.nick = $$v;
      },
      expression: "nick"
    }
  }), _c('label', {
    staticClass: "kiwi-customserver-have-password"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.show_password_box,
      expression: "show_password_box"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.show_password_box) ? _vm._i(_vm.show_password_box, null) > -1 : _vm.show_password_box
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.show_password_box,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.show_password_box = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context;
            $$i > -1 && (_vm.show_password_box = (0, _concat.default)(_context = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.show_password_box = $$c;
        }
      }
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t('password_have')) + " ")])]), _vm.show_password_box ? _c('input-text', {
    directives: [{
      name: "focus",
      rawName: "v-focus"
    }],
    attrs: {
      "label": _vm.$t('password'),
      "show-plain-text": true,
      "type": "password"
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }) : _vm._e(), _c('input-text', {
    attrs: {
      "label": _vm.$t('channel')
    },
    model: {
      value: _vm.channel,
      callback: function callback($$v) {
        _vm.channel = $$v;
      },
      expression: "channel"
    }
  })] : _vm._e(), _vm.server_type === 'default_simple' ? [_c('input-text', {
    staticClass: "kiwi-customserver-nick",
    attrs: {
      "label": _vm.$t('nick')
    },
    model: {
      value: _vm.nick,
      callback: function callback($$v) {
        _vm.nick = $$v;
      },
      expression: "nick"
    }
  }), _c('label', {
    staticClass: "kiwi-customserver-have-password"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.show_password_box,
      expression: "show_password_box"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.show_password_box) ? _vm._i(_vm.show_password_box, null) > -1 : _vm.show_password_box
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.show_password_box,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.show_password_box = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context2;
            $$i > -1 && (_vm.show_password_box = (0, _concat.default)(_context2 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context2, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.show_password_box = $$c;
        }
      }
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('password_have')))])]), _vm.show_password_box ? _c('input-text', {
    directives: [{
      name: "focus",
      rawName: "v-focus"
    }],
    attrs: {
      "label": _vm.$t('password'),
      "type": "password"
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }) : _vm._e(), _c('input-text', {
    staticClass: "kiwi-customserver-channel",
    attrs: {
      "label": _vm.$t('channel')
    },
    model: {
      value: _vm.channel,
      callback: function callback($$v) {
        _vm.channel = $$v;
      },
      expression: "channel"
    }
  })] : _vm._e(), _vm.server_type === 'znc' ? [_c('input-text', {
    attrs: {
      "label": _vm.$t('server')
    },
    model: {
      value: _vm.server,
      callback: function callback($$v) {
        _vm.server = $$v;
      },
      expression: "server"
    }
  }, [_c('span', {
    staticClass: "fa-stack fa-lg kiwi-customserver-tls",
    class: [_vm.tls ? 'kiwi-customserver-tls--enabled' : ''],
    on: {
      "click": function click($event) {
        _vm.tls = !_vm.tls;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-lock fa-stack-1x kiwi-customserver-tls-lock"
  }), !_vm.tls ? _c('i', {
    staticClass: "fa fa-times fa-stack-1x kiwi-customserver-tls-minus"
  }) : _vm._e()])]), _c('input-text', {
    staticClass: "kiwi-customserver-nick",
    attrs: {
      "label": _vm.$t('username')
    },
    model: {
      value: _vm.nick,
      callback: function callback($$v) {
        _vm.nick = $$v;
      },
      expression: "nick"
    }
  }), _vm.znc_network_support ? _c('input-text', {
    attrs: {
      "label": _vm.$t('network')
    },
    model: {
      value: _vm.znc_network,
      callback: function callback($$v) {
        _vm.znc_network = $$v;
      },
      expression: "znc_network"
    }
  }) : _vm._e(), _c('input-text', {
    attrs: {
      "label": _vm.$t('password'),
      "type": "password"
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })] : _vm._e(), _c('button', {
    staticClass: "u-button u-button-primary u-submit",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]), _vm.show_type_switcher ? _c('div', {
    staticClass: "kiwi-customserver-server-types"
  }, [_c('a', {
    staticClass: "u-link",
    on: {
      "click": function click($event) {
        _vm.server_type = 'default';
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('network')))]), _c('a', {
    staticClass: "u-link",
    on: {
      "click": function click($event) {
        _vm.server_type = 'znc';
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('znc')))])]) : _vm._e()], 2) : _c('div', {
    staticClass: "kiwi-customserver-loader"
  }, [_c('i', {
    staticClass: "fa fa-spin fa-spinner",
    attrs: {
      "aria-hidden": "true"
    }
  })])])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;