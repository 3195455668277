"use strict";
'kiwi public';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var Vue = require('vue');
Vue.component('tabbed-tab', {
  props: {
    header: {
      status: String
    },
    focus: {
      status: Boolean
    },
    name: {
      status: String
    }
  },
  data: function data() {
    return {
      active: false
    };
  },
  template: '<div v-if="active" class="u-tabbed-content"><slot></slot></div>'
});
var _default = Vue.component('tabbed-view', {
  data: function data() {
    return {
      // We increment this when we need to re-render the tabs.
      // Vue doesn't pick up on the $children changes all the time so we handle
      // it ourselves.
      a: 1,
      prefixID: Math.floor(Math.random() * 100000).toString(36)
    };
  },
  computed: {
    tabs: function computedtabs() {
      return this.$children;
    }
  },
  mounted: function mounted() {
    this.setActiveCheck();
  },
  methods: {
    getActive: function getActive() {
      var foundChild = null;
      this.$children.forEach(function (child) {
        if (child.active) {
          foundChild = child;
        }
      });
      return foundChild;
    },
    setActive: function setActive(c) {
      this.$children.forEach(function (child) {
        if (child !== c) {
          child.active = false;
        }
      });
      c.active = true;

      // Without this, vue doesnt update itself with the new $children :(
      this.a++;
      this.$emit('changed', c.name);
    },
    setActiveByName: function setActiveByName(name) {
      var _this = this;
      this.$children.forEach(function (child) {
        if (child.name === name) {
          _this.setActive(child);
        }
      });
    },
    setActiveCheck: function setActiveCheck() {
      var _this2 = this;
      this.$children.forEach(function (t) {
        if (t.focus) {
          _this2.setActive(t);
        }
      });
    }
  }
});
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["utils"]) window._kiwi_exports["components"]["utils"] = {};
window._kiwi_exports["components"]["utils"]["TabbedView"]
window._kiwi_exports.components.utils.TabbedView = exports.default ? exports.default : exports;
