"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _GlobalApi = _interopRequireDefault(require("@/libs/GlobalApi"));
var _StateBrowserNetwork = _interopRequireDefault(require("./StateBrowserNetwork"));
var _StateBrowserUsermenu = _interopRequireDefault(require("./StateBrowserUsermenu"));
var _AppSettings = _interopRequireDefault(require("./AppSettings"));
var _BufferSettings = _interopRequireDefault(require("./BufferSettings"));
var _default = {
  components: {
    BufferSettings: _BufferSettings.default,
    StateBrowserNetwork: _StateBrowserNetwork.default,
    StateBrowserUsermenu: _StateBrowserUsermenu.default
  },
  props: ['networks', 'sidebarState'],
  data: function data() {
    return {
      self: this,
      pluginUiElements: _GlobalApi.default.singleton().stateBrowserPlugins,
      activePrompt: {
        type: undefined,
        value: undefined
      }
    };
  },
  computed: {
    getNetwork: function getNetwork() {
      return this.$state.getActiveNetwork();
    },
    isPersistingState: function isPersistingState() {
      return !!this.$state.persistence;
    },
    isRestrictedServer: function isRestrictedServer() {
      return !!this.$state.settings.restricted;
    },
    networksToShow: function networksToShow() {
      var _context;
      return (0, _filter.default)(_context = this.networks).call(_context, function (net) {
        return !net.hidden;
      });
    }
  },
  created: function created() {
    var _this = this;
    this.listen(this.$state, 'document.clicked', function (e) {
      if (!_this.activePrompt.type) {
        // Prompt is not open
        return;
      }

      // Clicking anywhere on the page that is not a prompt or close button
      // closes the active prompt
      var ignoreClasses = ['.kiwi-statebrowser-prompt-close', '.kiwi-statebrowser-queries-close', '.kiwi-statebrowser-channel-leave'];
      var ignoreEls = _this.$el.querySelectorAll(ignoreClasses.join(', '));

      // ignoreEls.forEach((ignoreEl) => {
      for (var i = 0; i < ignoreEls.length; i++) {
        if (ignoreEls[i].contains(e.target)) {
          return;
        }
      }
      _this.activePrompt.type = undefined;
      _this.activePrompt.value = undefined;
    });
  },
  methods: {
    clickAddNetwork: function clickAddNetwork() {
      var nick = 'Guest' + Math.floor(Math.random() * 100);
      var network = this.$state.getNetworkFromAddress('');
      if (typeof network === 'undefined') {
        network = this.$state.addNetwork('Network', nick, {});
      }
      network.showServerBuffer('settings');
    },
    clickAppSettings: function clickAppSettings() {
      this.$state.$emit('active.component.toggle', _AppSettings.default);
    },
    hideStatebrowser: function hideStatebrowser() {
      this.$state.$emit('statebrowser.hide');
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["StateBrowser"]
window._kiwi_exports.components.StateBrowser = exports.default ? exports.default : exports;
