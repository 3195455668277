"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.def = def;
// Define a non-enumerable property on an object with an optional setter callback
function def(target, key, value, canSet) {
  var val = value;
  var definition = {
    get: function get() {
      return val;
    }
  };
  if (canSet) {
    definition.set = function set(newVal) {
      var oldVal = val;
      val = newVal;
      if (typeof canSet === 'function') {
        canSet(newVal, oldVal);
      }
    };
  }
  Object.defineProperty(target, key, definition);
  if (typeof canSet === 'function') {
    canSet(val);
  }
}