"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render(_c, _vm) {
  return _c(_vm.$options.c.Avatar, {
    tag: "component",
    attrs: {
      "user": _vm.props.user,
      "message": _vm.props.message,
      "size": "small"
    }
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;