"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-serverselector",
    class: {
      'kiwi-serverselector--custom': _vm.presetServer === 'custom'
    }
  }, [_vm.usePreset && _vm.presetNetworks.length > 0 ? _c('div', {
    staticClass: "kiwi-serverselector-presets"
  }, [_c('label', [_c('span', [_vm._v(_vm._s(_vm.$t('server')))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.presetServer,
      expression: "presetServer"
    }],
    on: {
      "change": function change($event) {
        var _context;
        var $$selectedVal = (0, _map.default)(_context = (0, _filter.default)(Array.prototype).call($event.target.options, function (o) {
          return o.selected;
        })).call(_context, function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.presetServer = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "custom"
    }
  }, [_vm._v("Custom Server")]), _c('option', {
    attrs: {
      "disabled": ""
    }
  }, [_vm._v("-----------------")]), _vm._l(_vm.presetNetworks, function (s) {
    return _c('option', {
      key: s.name,
      domProps: {
        "value": _vm.toUri(s)
      }
    }, [_vm._v(_vm._s(s.name))]);
  })], 2)])]) : _vm._e(), _vm.showCustom || _vm.presetNetworks.length === 0 || !_vm.usePreset ? [_c('input-text', {
    directives: [{
      name: "focus",
      rawName: "v-focus"
    }],
    staticClass: "kiwi-networksettings-connection-address",
    attrs: {
      "label": _vm.$t('server')
    },
    model: {
      value: _vm.connection.server,
      callback: function callback($$v) {
        _vm.$set(_vm.connection, "server", $$v);
      },
      expression: "connection.server"
    }
  }), _c('input-text', {
    staticClass: "kiwi-networksettings-connection-port",
    attrs: {
      "label": _vm.$t('settings_port'),
      "type": "number"
    },
    model: {
      value: _vm.connection.port,
      callback: function callback($$v) {
        _vm.$set(_vm.connection, "port", $$v);
      },
      expression: "connection.port"
    }
  }, [_c('span', {
    staticClass: "fa-stack fa-lg kiwi-customserver-tls",
    class: {
      'kiwi-customserver-tls--enabled': _vm.connection.tls
    },
    on: {
      "click": _vm.toggleTls
    }
  }, [_vm.connection.tls ? _c('i', {
    staticClass: "fa fa-lock fa-stack-1x kiwi-customserver-tls-lock"
  }) : _c('i', {
    staticClass: "fa fa-unlock fa-stack-1x kiwi-customserver-tls-minus"
  })])])] : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;