"use strict";
'kiwi public';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: ['network'],
  data: function data() {
    return {
      recaptchaUrl: '',
      recaptchaSiteId: '',
      recaptchaResponse: '',
      showCaptcha: false
    };
  },
  created: function created() {
    var _this = this;
    var options = this.$state.settings.startupOptions;
    this.recaptchaSiteId = options.recaptchaSiteId || '';
    this.recaptchaUrl = options.recaptchaUrl || 'https://www.google.com/recaptcha/api.js';
    this.listen(this.$state, 'network.connecting', function (event) {
      event.network.ircClient.once('socket connected', function () {
        if (_this.recaptchaResponse) {
          event.network.ircClient.raw('CAPTCHA', _this.recaptchaResponse);
        }
      });
    });
    this.listen(this.$state, 'irc.raw.CAPTCHA', function (command, event, network) {
      if (network !== _this.network) {
        return;
      }
      if (event.params[0] === 'NEEDED') {
        _this.loadRecaptcha();
      }
    });
  },
  methods: {
    loadRecaptcha: function loadRecaptcha() {
      var _this2 = this;
      if (window.grecaptcha) {
        this.recaptchaShow();
        return;
      }

      // Recaptcha calls this callback once it's loaded and ready to be used
      window.recaptchaLoaded = function () {
        _this2.recaptchaShow();
      };
      var scr = document.createElement('script');
      scr.src = this.recaptchaUrl + '?onload=recaptchaLoaded&render=explicit';
      scr.defer = true;
      document.head.appendChild(scr);
    },
    recaptchaShow: function recaptchaShow() {
      var _this3 = this;
      this.showCaptcha = true;
      this.$nextTick(function () {
        window.grecaptcha.render(_this3.$refs.captchacontainer, {
          'sitekey': _this3.recaptchaSiteId,
          'callback': _this3.recaptchaSuccess,
          'expired-callback': _this3.recaptchaExpired
        });
      });
    },
    recaptchaSuccess: function recaptchaSuccess(response) {
      this.recaptchaResponse = response;

      // If we have a network instance already, send the captcha response
      if (this.network && this.network.state === 'connecting') {
        this.network.ircClient.raw('CAPTCHA', response);
      }
      this.showCaptcha = false;
    },
    recaptchaExpired: function recaptchaExpired() {
      this.recaptchaResponse = '';
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["Captcha"]
window._kiwi_exports.components.Captcha = exports.default ? exports.default : exports;
