"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-startup-common",
    class: [_vm.closing ? 'kiwi-startup-common--closing' : '', _vm.backgroundImage ? '' : 'kiwi-startup-common--no-bg'],
    style: _vm.backgroundStyle
  }, [_c('div', {
    staticClass: "kiwi-startup-common-section kiwi-startup-common-section-connection"
  }, [_vm._t("connection")], 2), _c('div', {
    staticClass: "kiwi-startup-common-section kiwi-startup-common-section-info",
    style: _vm.backgroundStyle
  }, [_vm.infoContent ? _c('div', {
    staticClass: "kiwi-startup-common-section-info-content",
    domProps: {
      "innerHTML": _vm._s(_vm.infoContent)
    }
  }) : _vm._e()]), _vm._m(0)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-fontawesome-preload"
  }, [_c('i', {
    staticClass: "fa fa-spinner",
    attrs: {
      "aria-hidden": "true"
    }
  })]);
}];
exports.staticRenderFns = staticRenderFns;