"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-inputtool-emoji"
  }, [_c('div', {
    staticClass: "kiwi-inputtool-emoji-palette",
    on: {
      "mousedown": function mousedown($event) {
        $event.preventDefault();
      },
      "click": function click($event) {
        $event.preventDefault();
      }
    }
  }, _vm._l(_vm.emojis, function (text, eCode) {
    var _context;
    return _c('div', {
      key: eCode,
      staticClass: "kiwi-inputtool-emoji-emoji",
      style: {
        'background-image': (0, _concat.default)(_context = "url(".concat(_vm.location)).call(_context, eCode, ")")
      },
      attrs: {
        "data-code": text
      },
      on: {
        "click": _vm.onImgClick
      }
    });
  }), 0)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;