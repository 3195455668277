"use strict";
'kiwi public';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var Vue = require('vue');

// Code created from https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/
var _default = Vue.component('transition-expand', {
  name: 'TransitionExpand',
  methods: {
    enter: function enter(element) {
      var width = getComputedStyle(element).width;
      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';
      var height = getComputedStyle(element).height;
      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      // eslint-disable-next-line no-unused-expressions
      getComputedStyle(element).height;

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(function () {
        element.style.height = height;
      });
    },
    afterEnter: function afterEnter(element) {
      element.style.height = 'auto';
    },
    leave: function leave(element) {
      var height = getComputedStyle(element).height;
      element.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      // eslint-disable-next-line no-unused-expressions
      getComputedStyle(element).height;
      requestAnimationFrame(function () {
        element.style.height = 0;
      });
    }
  }
});
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["utils"]) window._kiwi_exports["components"]["utils"] = {};
window._kiwi_exports["components"]["utils"]["TransitionExpand"]
window._kiwi_exports.components.utils.TransitionExpand = exports.default ? exports.default : exports;
