"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-wrap kiwi-wrap-error"
  }, [_c('h2', [_vm._v("Kiwi IRC could not start :(")]), _c('div', [_vm._v(_vm._s(_vm.error))])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;