"use strict";
'kiwi public';

// Helper to generate Vues computed methods for simple channel modes.
// Eg. +i, +n, etc
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
function generateComputedMode(mode) {
  return {
    get: function computedModeGet() {
      return this.modeVal(mode);
    },
    set: function computedModeSet(newVal) {
      return this.setMode((newVal ? '+' : '-') + mode);
    }
  };
}

// Helper to generate Vues computed methods for channel modes with a parameter
// Eg. "+k key"
function generateComputedModeWithParam(mode) {
  return {
    get: function computedModeWithParamGet() {
      var val = this.modeVal(mode);
      return val === false ? '' : val;
    },
    set: function computedModeWithParamSet(newVal) {
      if (newVal) {
        this.setMode('+' + mode, newVal);
      } else {
        this.setMode('-' + mode, this.modeVal(mode));
      }
    }
  };
}
var _default = {
  props: ['buffer'],
  data: function data() {
    return {
      showPrevTopics: false
    };
  },
  computed: {
    modeM: generateComputedMode('m'),
    modeI: generateComputedMode('i'),
    modeS: generateComputedMode('s'),
    modeT: generateComputedMode('t'),
    modeN: generateComputedMode('n'),
    modeK: generateComputedModeWithParam('k'),
    topic: {
      get: function computedTopicGet() {
        return this.buffer.topic;
      },
      set: function computedTopicSet(newVal) {
        var newTopic = newVal.replace('\n', ' ');
        // TODO: Update irc-framework to insert a trailing : if the last argument is an
        //       empty string. The trailing : makes a difference between things like
        //       requesting a topic and changing to an empty topic
        if (!(0, _trim.default)(newTopic).call(newTopic)) {
          this.buffer.getNetwork().ircClient.raw("TOPIC ".concat(this.buffer.name, " :"));
        } else {
          this.buffer.getNetwork().ircClient.setTopic(this.buffer.name, newTopic);
        }
      }
    },
    prevTopics: function prevTopics() {
      var _context;
      return (0, _filter.default)(_context = this.buffer.topics).call(_context, function (topic) {
        return (0, _trim.default)(topic).call(topic);
      });
    }
  },
  methods: {
    updateBanList: function updateBanList() {
      this.buffer.getNetwork().ircClient.raw('MODE', this.buffer.name, '+b');
    },
    setMode: function setMode(mode, param) {
      this.buffer.getNetwork().ircClient.raw('MODE', this.buffer.name, mode, param);
    },
    modeVal: function modeVal(mode) {
      var val = false;
      if (typeof this.buffer.modes[mode] === 'undefined') {
        // Specifically undefined = mode not set
        val = false;
      } else if (!this.buffer.modes[mode]) {
        // Falsy value = mode set without value
        val = true;
      } else {
        // Anything else = mode set with a value
        val = this.buffer.modes[mode];
      }
      return val;
    },
    areWeAnOp: function areWeAnOp() {
      return this.buffer.isUserAnOp(this.buffer.getNetwork().nick);
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["ChannelInfo"]
window._kiwi_exports.components.ChannelInfo = exports.default ? exports.default : exports;
