"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.get = get;
exports.set = set;
var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));
var supportedCached = null;
function get(name) {
  if (!isSupported()) {
    return _promise.default.resolve();
  }
  return new _promise.default(function (resolve) {
    resolve(window.localStorage.getItem(name));
  });
}
function set(name, val) {
  if (!isSupported()) {
    return _promise.default.resolve();
  }
  return new _promise.default(function (resolve) {
    resolve(window.localStorage.setItem(name, val));
  });
}
function isSupported() {
  if (supportedCached === null) {
    supportedCached = storageAvailable('localStorage');
  }
  return supportedCached;
}

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
function storageAvailable(type) {
  var storage;
  try {
    var x = '__storage_test__';
    storage = window[type];
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return e instanceof DOMException && (
    // everything except Firefox
    e.code === 22 ||
    // Firefox
    e.code === 1014 ||
    // test name field too, because code might not be present
    // everything except Firefox
    e.name === 'QuotaExceededError' ||
    // Firefox
    e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
    // acknowledge QuotaExceededError only if there's something already stored
    storage.length !== 0;
  }
}