"use strict";
'kiwi public';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  components: {},
  props: ['buffer', 'message'],
  data: function data() {
    return {
      requestingInput: false
    };
  },
  computed: {},
  methods: {
    urlPreview: function urlPreview(url) {
      this.$state.$emit('mediaviewer.show', url);
    },
    areWeAnOp: function areWeAnOp() {
      var ourNick = this.buffer.getNetwork().nick;
      return this.buffer.isUserAnOp(ourNick);
    },
    isSelf: function isSelf() {
      var user = this.$state.getUser(this.buffer.getNetwork().id, this.message.nick);
      return this.buffer.getNetwork().ircClient.user.nick === user.nick;
    },
    onBan: function onBan(reason) {
      var network = this.buffer.getNetwork();
      network.ircClient.mode(this.buffer.name, '+b', this.message.nick);
    },
    onKick: function onKick(promptedReason) {
      var network = this.buffer.getNetwork();
      var defaultReason = this.$state.setting('buffers.default_kick_reason');
      var reason = promptedReason || defaultReason;
      network.ircClient.raw('KICK', this.buffer.name, this.message.nick, reason);
    },
    openQuery: function openQuery() {
      var network = this.buffer.getNetwork();
      var buffer = this.$state.addBuffer(network.id, this.message.nick);
      this.$state.setActiveBuffer(network.id, buffer.name);
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["MessageInfo"]
window._kiwi_exports.components.MessageInfo = exports.default ? exports.default : exports;
