"use strict";
'kiwi public';

/** @module */

/**
 * Adds the +typing IRCv3 spec to irc-framework
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = typingMiddleware;
function typingMiddleware() {
  return function middleware(client, rawEvents, parsedEvents) {
    addFunctionsToClient(client);
    rawEvents.use(theMiddleware);
  };
  function theMiddleware(command, message, rawLine, client, next) {
    if (!(command === 'TAGMSG' && message.tags['+typing']) && !(command === 'PRIVMSG' && message.nick)) {
      next();
      return;
    }

    // if we are params[0] then the target is the sender (direct message)
    var target = message.params[0].toLowerCase() === client.user.nick.toLowerCase() ? message.nick : message.params[0];

    // if its a privmsg without typing tag emit done
    var status = message.tags['+typing'] || 'done';
    client.emit('typing', {
      target: target,
      nick: message.nick,
      ident: message.ident,
      hostname: message.hostname,
      status: status
    });
    next();
  }
}
function addFunctionsToClient(client) {
  var typing = client.typing = {};
  var activeTyping = Object.create(null);
  function isEnabled() {
    return client.network.cap.isEnabled('message-tags');
  }
  typing.start = function start(target) {
    if (!isEnabled()) {
      return;
    }
    var lastSentStatus = activeTyping[target.toLowerCase()];
    if (lastSentStatus && lastSentStatus > Date.now() - 3000) {
      return;
    }
    activeTyping[target.toLowerCase()] = Date.now();
    var message = new client.Message('TAGMSG', target);
    message.tags['+typing'] = 'active';
    client.raw(message);
  };
  typing.pause = function pause(target) {
    if (!isEnabled()) {
      return;
    }
    if (!activeTyping[target.toLowerCase()]) {
      // Did not start typing so cannot pause
      return;
    }
    var message = new client.Message('TAGMSG', target);
    message.tags['+typing'] = 'paused';
    client.raw(message);
  };
  typing.stop = function stop(target, sendStop) {
    if (!isEnabled()) {
      return;
    }
    if (!activeTyping[target.toLowerCase()]) {
      // Did not start typing so cannot stop
      return;
    }
    delete activeTyping[target.toLowerCase()];
    if (!sendStop) {
      // Stop was called after a message was sent,
      // only a cleanup of activeTyping is needed
      return;
    }
    var message = new client.Message('TAGMSG', target);
    message.tags['+typing'] = 'done';
    client.raw(message);
  };
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["TypingMiddleware"]
window._kiwi_exports.libs.TypingMiddleware = exports.default ? exports.default : exports;
