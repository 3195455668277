"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _flags = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/flags"));
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-statebrowser-channel-wrapper"
  }, [_c('div', {
    staticClass: "kiwi-statebrowser-channel",
    class: {
      'kiwi-statebrowser-channel-active': _vm.isActiveBuffer(),
      'kiwi-statebrowser-channel-notjoined': _vm.buffer.isChannel() && !_vm.buffer.joined
    },
    attrs: {
      "data-name": _vm.buffer.name.toLowerCase()
    }
  }, [_c('div', {
    staticClass: "kiwi-statebrowser-channel-name",
    on: {
      "click": function click($event) {
        return _vm.$emit('selected');
      }
    }
  }, [_vm.buffer.isQuery() && _vm.awayNotifySupported() ? _c('away-status-indicator', {
    attrs: {
      "network": _vm.network,
      "user": _vm.network.userByName(_vm.buffer.name)
    }
  }) : _vm._e(), _vm._v(_vm._s(_vm.buffer.name) + " ")], 1), _c('div', {
    staticClass: "kiwi-statebrowser-buffer-actions"
  }, [_c('div', {
    staticClass: "kiwi-statebrowser-channel-labels"
  }, [(0, _flags.default)(_vm.buffer).unread && _vm.showMessageCounts(_vm.buffer) ? _c('div', {
    staticClass: "kiwi-statebrowser-channel-label",
    class: [(0, _flags.default)(_vm.buffer).highlight ? 'kiwi-statebrowser-channel-label--highlight' : '']
  }, [_vm._v(" " + _vm._s((0, _flags.default)(_vm.buffer).unread > 999 ? "999+" : (0, _flags.default)(_vm.buffer).unread) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "kiwi-statebrowser-channel-leave",
    on: {
      "click": function click($event) {
        return _vm.maybePromptClose();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-times",
    attrs: {
      "aria-hidden": "true"
    }
  })])])]), _c('transition-expand', [_vm.showPromptClose ? _c('div', {
    staticClass: "kiwi-statebrowser-prompt-close"
  }, [_c('span', [_vm._v(_vm._s(_vm.buffer.isChannel() ? _vm.$t('prompt_leave_channel') : _vm.$t('prompt_close_query')))]), _c('input-confirm', {
    attrs: {
      "flip-connotation": true
    },
    on: {
      "ok": function ok($event) {
        return _vm.closeBuffer();
      },
      "submit": function submit($event) {
        return _vm.maybePromptClose();
      }
    }
  })], 1) : _vm._e()])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;