"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('img', {
    ref: "kiwi-loading-logo",
    staticClass: "kiwi-loading-logo",
    attrs: {
      "src": require("../res/kiwiLoadingLogo.png"),
      "alt": ""
    }
  }), _c('canvas', {
    ref: "kiwi-loading-canvas",
    staticClass: "kiwi-loading-animation"
  })]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;