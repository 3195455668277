"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('startup-layout', {
    ref: "layout",
    staticClass: "kiwi-startbnc",
    scopedSlots: _vm._u([{
      key: "connection",
      fn: function fn() {
        return [_c('form', {
          staticClass: "kiwi-startbnc-form",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.startUp.apply(null, arguments);
            }
          }
        }, [_c('h2', {
          domProps: {
            "innerHTML": _vm._s(_vm.greetingText)
          }
        }), _c('div', {
          staticClass: "kiwi-startbnc-status"
        }, [_vm._v(_vm._s(_vm.statusMessage))]), _c('label', [_c('span', [_vm._v(_vm._s(_vm.$t('username')))]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.username,
            expression: "username"
          }],
          attrs: {
            "disabled": _vm.loading,
            "type": "text"
          },
          domProps: {
            "value": _vm.username
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.username = $event.target.value;
            }
          }
        })]), _c('label', [_c('span', [_vm._v(_vm._s(_vm.$t('password')))]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.password,
            expression: "password"
          }],
          attrs: {
            "disabled": _vm.loading,
            "type": "password"
          },
          domProps: {
            "value": _vm.password
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.password = $event.target.value;
            }
          }
        })]), _c('button', {
          staticClass: "u-button u-button-primary u-submit",
          attrs: {
            "disabled": _vm.loading || !_vm.username || !_vm.password,
            "type": "submit"
          }
        }, [!_vm.loading ? _c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.buttonText)
          }
        }) : _c('i', {
          staticClass: "fa fa-spinner fa-spin",
          attrs: {
            "aria-hidden": "true"
          }
        })])])];
      },
      proxy: true
    }])
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;