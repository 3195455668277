"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render(_c, _vm) {
  return _c('div', {
    staticClass: "kiwi-nicklist-user",
    class: [_vm.props.nicklist.userMode(_vm.props.user) ? 'kiwi-nicklist-user--mode-' + _vm.props.nicklist.userMode(_vm.props.user) : '', _vm.props.user.away ? 'kiwi-nicklist-user--away' : '', _vm.props.user.ignore ? 'kiwi-nicklist-user--ignore' : '', _vm.data.staticClass],
    attrs: {
      "data-nick": (_vm.props.user.nick || '').toLowerCase()
    },
    on: {
      "click": function click($event) {
        return _vm.props.nicklist.openUserbox(_vm.props.user);
      }
    }
  }, [_vm.props.m().shouldShowAvatars() ? _c('div', {
    staticClass: "kiwi-avatar-container"
  }, [_vm.props.user ? _c(_vm.injections.components.Avatar, {
    tag: "component",
    attrs: {
      "user": _vm.props.user,
      "size": "small"
    }
  }) : _vm._e(), _c(_vm.injections.components.AwayStatusIndicator, {
    tag: "component",
    attrs: {
      "network": _vm.props.network,
      "user": _vm.props.user,
      "toggle": false
    }
  })], 1) : _c('div', [_c(_vm.injections.components.AwayStatusIndicator, {
    tag: "component",
    attrs: {
      "network": _vm.props.network,
      "user": _vm.props.user,
      "toggle": false
    }
  })], 1), _c('span', {
    staticClass: "kiwi-nicklist-user-prefix"
  }, [_vm._v(" " + _vm._s(_vm.props.nicklist.userModePrefix(_vm.props.user)) + " ")]), _c('span', {
    staticClass: "kiwi-nicklist-user-nick",
    style: {
      'color': _vm.props.m().userColour()
    }
  }, [_vm._v(_vm._s(_vm.props.user.nick) + " ")]), _c('span', {
    staticClass: "kiwi-nicklist-messageuser",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.props.nicklist.openQuery(_vm.props.user);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-comment",
    attrs: {
      "aria-hidden": "true"
    }
  })]), _c(_vm.injections.components.TypingStatusIndicator, {
    tag: "component",
    attrs: {
      "user": _vm.props.user,
      "buffer": _vm.props.nicklist.buffer
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;