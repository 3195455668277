"use strict";
'kiwi public';

// What settings keys to ignore when building period delimited settings object
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildTree = buildTree;
var _typeof2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/typeof"));
var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var ignoreKeys = ['emojis', 'themes', 'bnc', 'aliases', 'restricted', 'kiwiServer', 'hide_advanced', 'windowTitle', 'startupOptions', 'plugins', 'presetNetworks', 'ircFramework'];

// Converts settings object to period delimited key based object
// eg { buffers.alert_on: false }
function buildTree(data, base, object, modified) {
  (0, _keys.default)(object).forEach(function (key) {
    var value = object[key];
    var ourBase = (0, _concat.default)(base).call(base, [key]);
    if (['string', 'boolean', 'number'].indexOf((0, _typeof2.default)(value)) !== -1) {
      if (ignoreKeys.indexOf(key) !== -1 || (ourBase[0] && ignoreKeys.indexOf(ourBase[0])) !== -1) {
        return;
      }
      if (!data[ourBase.join('.')] || data[ourBase.join('.')].val !== value) {
        data[ourBase.join('.')] = {
          key: ourBase.join('.'),
          val: value,
          type: (0, _typeof2.default)(value),
          modified: modified
        };
      }
    } else if ((0, _typeof2.default)(value) === 'object' && value !== null) {
      buildTree(data, ourBase, value, modified);
    }
  });
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["settingTools"]
window._kiwi_exports.libs.settingTools = exports.default ? exports.default : exports;
