"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-notconnected kiwi-warning-block",
    class: {
      connecting: _vm.shouldShowLoading
    }
  }, [!_vm.shouldShowLoading ? [_c('div', {
    staticClass: "kiwi-notconnected-caption"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('not_connected')))]), !_vm.shouldShowLoading ? _c('i', {
    staticClass: "fa fa-frown-o",
    attrs: {
      "aria-hidden": "true"
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "kiwi-notconnected-buttons"
  }, [_vm.isChannel() ? [_c('span', {
    staticClass: "kiwi-notconnected-button",
    attrs: {
      "disabled": !_vm.readyToStart
    },
    on: {
      "click": _vm.reconnect
    }
  }, [_c('i', {
    staticClass: "fa fa-arrow-circle-o-right",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('reconnect_channel', {
    channel: _vm.buffer.name
  })) + " ")])] : _vm.isServer() ? [_c('span', {
    staticClass: "kiwi-notconnected-button",
    attrs: {
      "disabled": !_vm.readyToStart
    },
    on: {
      "click": _vm.reconnect
    }
  }, [_c('i', {
    staticClass: "fa fa-arrow-circle-o-right",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('reconnect_network', {
    network: _vm.buffer.getNetwork().name
  })) + " ")])] : _vm.isQuery() ? [_c('span', {
    staticClass: "kiwi-notconnected-button",
    attrs: {
      "disabled": !_vm.readyToStart
    },
    on: {
      "click": _vm.reconnect
    }
  }, [_c('i', {
    staticClass: "fa fa-arrow-circle-o-right",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('reconnect_query', {
    user: _vm.buffer.name
  })) + " ")])] : _vm._e(), !_vm.restrictedServer ? _c('a', {
    staticClass: "kiwi-notconnected-button kiwi-notconnected-button-settings",
    on: {
      "click": _vm.showNetworkSettings
    }
  }, [_c('i', {
    staticClass: "fa fa-cogs",
    attrs: {
      "aria-hidden": "true"
    }
  })]) : _vm._e()], 2)] : _c('div', {
    staticClass: "kiwi-notconnected-caption"
  }, [_vm._v(" " + _vm._s(_vm.$t('connecting')) + " "), _c('i', {
    staticClass: "fa fa-refresh fa-spin kiwi-notconnected-bigicon",
    attrs: {
      "aria-hidden": "true"
    }
  })]), _c('captcha', {
    staticClass: "kiwi-notconnected-captcha",
    attrs: {
      "network": _vm.network
    }
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;