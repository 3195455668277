"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('startup-layout', {
    ref: "layout",
    staticClass: "kiwi-welcome-simple",
    scopedSlots: _vm._u([_vm.startupOptions.altComponent ? {
      key: "connection",
      fn: function fn() {
        return [_c(_vm.startupOptions.altComponent, {
          tag: "component",
          on: {
            "close": _vm.onAltClose
          }
        })];
      },
      proxy: true
    } : {
      key: "connection",
      fn: function fn() {
        return [_c('form', {
          staticClass: "u-form u-form--big kiwi-welcome-simple-form",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.formSubmit.apply(null, arguments);
            }
          }
        }, [_c('h2', {
          domProps: {
            "innerHTML": _vm._s(_vm.greetingText)
          }
        }), _vm.network && (_vm.connectErrors.length > 0 || _vm.network.state_error) ? _c('div', {
          staticClass: "kiwi-welcome-simple-error"
        }, [_vm.connectErrors.length > 0 ? _vm._l(_vm.connectErrors, function (err) {
          return _c('span', {
            key: err
          }, [_vm._v(_vm._s(err))]);
        }) : [_c('span', [_vm._v(_vm._s(_vm.$t('network_noconnect')))]), _c('span', [_vm._v(_vm._s(_vm.readableStateError(_vm.network.state_error)))])]], 2) : _vm._e(), _c('input-text', {
          directives: [{
            name: "focus",
            rawName: "v-focus",
            value: !_vm.nick || !_vm.show_password_box,
            expression: "!nick || !show_password_box"
          }],
          class: {
            'kiwi-welcome-invalid-nick': !_vm.isNickValid
          },
          attrs: {
            "label": _vm.$t('nick'),
            "type": "text"
          },
          model: {
            value: _vm.nick,
            callback: function callback($$v) {
              _vm.nick = $$v;
            },
            expression: "nick"
          }
        }), _vm.showPass && _vm.toggablePass ? _c('div', {
          staticClass: "kiwi-welcome-simple-input-container"
        }, [_c('label', {
          staticClass: "kiwi-welcome-simple-have-password"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.show_password_box,
            expression: "show_password_box"
          }],
          attrs: {
            "type": "checkbox"
          },
          domProps: {
            "checked": Array.isArray(_vm.show_password_box) ? _vm._i(_vm.show_password_box, null) > -1 : _vm.show_password_box
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.show_password_box,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && (_vm.show_password_box = (0, _concat.default)($$a).call($$a, [$$v]));
                } else {
                  var _context;
                  $$i > -1 && (_vm.show_password_box = (0, _concat.default)(_context = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context, (0, _slice.default)($$a).call($$a, $$i + 1)));
                }
              } else {
                _vm.show_password_box = $$c;
              }
            }
          }
        }), _c('span', [_vm._v(" " + _vm._s(_vm.$t('password_have')) + " ")])])]) : _vm._e(), _vm.showPass && (_vm.show_password_box || !_vm.toggablePass) ? _c('div', {
          staticClass: "kiwi-welcome-simple-input-container"
        }, [_c('input-text', {
          directives: [{
            name: "focus",
            rawName: "v-focus",
            value: _vm.nick || _vm.show_password_box,
            expression: "nick || show_password_box"
          }],
          attrs: {
            "show-plain-text": true,
            "label": _vm.$t('password'),
            "type": "password"
          },
          model: {
            value: _vm.password,
            callback: function callback($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        })], 1) : _vm._e(), _vm.showChannel ? _c('div', {
          staticClass: "kiwi-welcome-simple-input-container"
        }, [_c('input-text', {
          attrs: {
            "label": _vm.$t('channel')
          },
          model: {
            value: _vm.channel,
            callback: function callback($$v) {
              _vm.channel = $$v;
            },
            expression: "channel"
          }
        })], 1) : _vm._e(), _vm.termsContent ? _c('div', {
          staticClass: "kiwi-welcome-simple-terms"
        }, [_c('div', [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.termsAccepted,
            expression: "termsAccepted"
          }],
          attrs: {
            "type": "checkbox"
          },
          domProps: {
            "checked": Array.isArray(_vm.termsAccepted) ? _vm._i(_vm.termsAccepted, null) > -1 : _vm.termsAccepted
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.termsAccepted,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && (_vm.termsAccepted = (0, _concat.default)($$a).call($$a, [$$v]));
                } else {
                  var _context2;
                  $$i > -1 && (_vm.termsAccepted = (0, _concat.default)(_context2 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context2, (0, _slice.default)($$a).call($$a, $$i + 1)));
                }
              } else {
                _vm.termsAccepted = $$c;
              }
            }
          }
        })]), _c('div', {
          staticClass: "kiwi-welcome-simple-terms-content",
          domProps: {
            "innerHTML": _vm._s(_vm.termsContent)
          }
        })]) : _vm._e(), _c('captcha', {
          attrs: {
            "network": _vm.network
          }
        }), !_vm.network || _vm.network.state === 'disconnected' ? _c('button', {
          staticClass: "u-button u-button-primary u-submit kiwi-welcome-simple-start",
          attrs: {
            "disabled": !_vm.readyToStart,
            "type": "submit"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.buttonText)
          }
        }) : _c('button', {
          staticClass: "u-button u-button-primary u-submit kiwi-welcome-simple-start",
          attrs: {
            "type": "button",
            "disabled": ""
          }
        }, [_c('i', {
          staticClass: "fa fa-spin fa-spinner",
          attrs: {
            "aria-hidden": "true"
          }
        })]), _c('div', {
          domProps: {
            "innerHTML": _vm._s(_vm.footerText)
          }
        })], 1)];
      },
      proxy: true
    }], null, true)
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;