"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _values = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/values"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));
var _TypingStatusIndicator = _interopRequireDefault(require("./TypingStatusIndicator"));
var _default = {
  components: {
    TypingStatusIndicator: _TypingStatusIndicator.default
  },
  props: ['buffer'],
  computed: {
    users: function users() {
      if (this.buffer.isQuery()) {
        // if this is a query return the target as users
        var network = this.$state.getActiveNetwork();
        var user = this.$state.getUser(network.id, this.buffer.name);
        return user ? (0, _defineProperty2.default)({}, user.nick, user) : {};
      }
      return this.buffer.users;
    },
    typingUsers: function typingUsers() {
      var _context,
        _this = this;
      var myNick = this.$state.getActiveNetwork().nick;
      return (0, _filter.default)(_context = (0, _values.default)(this.users)).call(_context, function (u) {
        return u.nick !== myNick && u.typingStatus(_this.buffer.name).status;
      });
    }
  },
  methods: {
    userColour: function userColour(user) {
      return user && this.buffer.setting('colour_nicknames_in_messages') ? user.getColour() : '';
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["TypingUsersList"]
window._kiwi_exports.components.TypingUsersList = exports.default ? exports.default : exports;
