"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "State", {
  enumerable: true,
  get: function get() {
    return _SidebarState.default;
  }
});
exports.default = void 0;
var _UserBox = _interopRequireDefault(require("@/components/UserBox"));
var _GlobalApi = _interopRequireDefault(require("@/libs/GlobalApi"));
var _SidebarState = _interopRequireDefault(require("./SidebarState"));
var _BufferSettings = _interopRequireDefault(require("./BufferSettings"));
var _ChannelInfo = _interopRequireDefault(require("./ChannelInfo"));
var _SidebarAboutBuffer = _interopRequireDefault(require("./SidebarAboutBuffer"));
var _ChannelBanlist = _interopRequireDefault(require("./ChannelBanlist"));
var _ChannelInvitelist = _interopRequireDefault(require("./ChannelInvitelist"));
var _Nicklist = _interopRequireDefault(require("./Nicklist"));
var _default = {
  components: {
    BufferSettings: _BufferSettings.default,
    SidebarAboutBuffer: _SidebarAboutBuffer.default,
    ChannelInfo: _ChannelInfo.default,
    ChannelBanlist: _ChannelBanlist.default,
    ChannelInvitelist: _ChannelInvitelist.default,
    Nicklist: _Nicklist.default,
    UserBox: _UserBox.default
  },
  props: ['network', 'buffer', 'sidebarState'],
  data: function data() {
    return {
      pluginUiElements: _GlobalApi.default.singleton().sideBarPlugins,
      accessTab: 'banlist'
    };
  },
  computed: {
    section: function section() {
      if (this.sidebarState.activeComponent) {
        return 'component';
      }
      return this.sidebarState.section();
    },
    settingShowJoinParts: {
      get: function get() {
        return this.buffer.setting('show_joinparts');
      },
      set: function set(newVal) {
        return this.buffer.setting('show_joinparts', newVal);
      }
    },
    settingShowTopics: {
      get: function get() {
        return this.buffer.setting('show_topics');
      },
      set: function set(newVal) {
        return this.buffer.setting('show_topics', newVal);
      }
    },
    settingShowNickChanges: {
      get: function get() {
        return this.buffer.setting('show_nick_changes');
      },
      set: function set(newVal) {
        return this.buffer.setting('show_nick_changes', newVal);
      }
    },
    settingShowModeChanges: {
      get: function get() {
        return this.buffer.setting('show_mode_changes');
      },
      set: function set(newVal) {
        return this.buffer.setting('show_mode_changes', newVal);
      }
    },
    settingColouredNicklist: {
      get: function get() {
        return this.buffer.setting('coloured_nicklist');
      },
      set: function set(newVal) {
        return this.buffer.setting('coloured_nicklist', newVal);
      }
    },
    settingExtraFormatting: {
      get: function get() {
        return this.buffer.setting('extra_formatting');
      },
      set: function set(newVal) {
        return this.buffer.setting('extra_formatting', newVal);
      }
    },
    settingShareTyping: {
      get: function getSettingShareTyping() {
        return this.buffer.setting('share_typing');
      },
      set: function setSettingShareTyping(newVal) {
        return this.buffer.setting('share_typing', newVal);
      }
    },
    bufferType: function bufferType() {
      var type = '';
      if (!this.buffer) {
        type = 'none';
      } else if (this.buffer.isServer()) {
        type = 'server';
      } else if (this.buffer.isChannel()) {
        type = 'channel';
      } else if (this.buffer.isQuery()) {
        type = 'query';
      }
      return type;
    }
  },
  created: function created() {
    var _this = this;
    this.listen(this.$state, 'sidebar.tab.show', function (tabName) {
      _this.showTab(tabName);
    });
  },
  methods: {
    showTab: function showTab(tabName) {
      this.$refs.tabs.setActiveByName(tabName);
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["Sidebar"]
window._kiwi_exports.components.Sidebar = exports.default ? exports.default : exports;
